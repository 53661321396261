export default [
	{
		title: 'Home',
		path: '/',
	},
	// {
	// 	title: 'About Us',
	// 	path: '/about',
	// },
	{
		title: 'Projects',
		path: '/projects',
	},
	// {
	// 	title: 'Features',
	// 	path: '/features',
	// },
	// {
	// 	title: 'Documentaries',
	// 	path: '/documentaries',
	// },
	// {
	// 	title: 'Tv Series',
	// 	path: '/series',
	// },
	// {
	// 	title: 'Team',
	// 	path: '/team',
	// },
	// {
	// 	title: 'Contact Us',
	// 	path: '/contact',
	// },
];
