import React from 'react';

const BackToTop = () => {
	return (
		<>
			<div className='md-overlay' />
			<a
				onClick={() => window.scrollTo(0, 0)}
				className='backtotop'
				id='backtotop'
			>
				<i className='ion-android-arrow-up' />
			</a>
		</>
	);
};

export default BackToTop;
