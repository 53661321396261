import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import BackToTop from './components/BackToTop';
import Navbar from './components/Navbar';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import ProjectDetailsPage from './pages/ProjectDetailsPage';
import ProjectsPage from './pages/ProjectsPage';
import TeamDetails from './pages/TeamDetails';
import NotFoundPage from './pages/NotFoundPage';

function App() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<div style={{ position: 'relative', overflowY: 'hidden' }}>
			<div
				className={`site`}
				style={{
					minHeight: '100vh',
				}}
			>
				<Navbar />
				<div id='main'>
					<Routes>
						<Route path='/' element={<HomePage />} />
						<Route path='/about' element={<AboutPage />} />
						<Route path='/contact' element={<ContactPage />} />
						<Route path='/projects'>
							<Route index element={<ProjectsPage />} />
							<Route path=':projectId' element={<ProjectDetailsPage />} />
							<Route path=':projectId/team/:id' element={<TeamDetails />} />
						</Route>
						<Route path='*' element={<NotFoundPage />} />
					</Routes>
				</div>
				{/* <Footer /> */}
			</div>
			<BackToTop />
		</div>
	);
}

export default App;
