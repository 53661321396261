import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import './HomeBanner.css';

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import bannerData from '../../data/projects.data';
import { Link } from 'react-router-dom';

const Wrapper = ({ imgSrc, children }) => {
	return (
		<li
			style={{
				backgroundImage: `url(${imgSrc})`,
			}}
			className='wrapper'
		>
			{children}
		</li>
	);
};

const HomeBanner = () => {
	return (
		<div className='section'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-sm-12 p-0'>
						<Swiper
							spaceBetween={30}
							effect={'fade'}
							navigation={true}
							pagination={{
								clickable: true,
							}}
							modules={[EffectFade, Navigation]}
							className='fullscreenbanner'
							style={
								{
									// paddingTop: '90px',
									// background: 'black',
									// height: '100vh',
								}
							}
						>
							{bannerData.map((item, index) => (
								<SwiperSlide key={index}>
									<Wrapper
										imgSrc={
											item.image
											// || 'images/slider/slider_1.jpg'
										}
									>
										<Link
											to={`/projects/${item.id}`}
											className='p-3 banner__info'
											style={{
												background: 'rgba(0,0,0,0.5)',
											}}
										>
											<div
												className='mon-100-700 tp-resizeme'
												style={{
													fontSize: '56px',
													lineHeight: '48px',
												}}
											>
												{item.title}
											</div>
											<div
												className='mt-3'
												style={{
													display: 'flex',
													justifyContent: 'center',
													flexWrap: 'wrap',
												}}
											>
												{item.category.map((c, index) => (
													<div
														key={index}
														className='mon-64-700 tp-resizeme'
														style={{
															fontSize: '28px',
															color: 'red',
															lineHeight: '35px',
														}}
													>
														{c}
														{index !== item.category.length - 1 && (
															<span className='ml-1 mr-1'>/</span>
														)}
													</div>
												))}
											</div>
										</Link>
									</Wrapper>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeBanner;
