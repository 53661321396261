import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_PROJECT, GET_TEAM } from '../../services/movies.service';
import Breadcrumb from '../../components/Breadcrumb';
import teamData from '../../data/team.data';
import TeamCard from '../../components/TeamCard';

const ProjectDetailsPage = () => {
	const [movie, setMovie] = useState(null);
	const [team, setTeam] = useState([]);
	const { projectId } = useParams();

	useEffect(() => {
		getData(projectId);
	}, [projectId]);

	const getData = async (id) => {
		const tesProject = await GET_PROJECT(id);
		const resTeam = await GET_TEAM(id);
		setMovie(tesProject);
		setTeam(resTeam);
	};

	if (!movie) return null;
	return (
		<>
			{/* <iframe
				style={{
					height: '90vh',
				}}
				src={movie.frame_link}
			></iframe> */}
			<div>
				<Breadcrumb
					title={movie.title}
					links={[
						{
							title: 'Projects',
							path: '/projects',
						},
					]}
				/>
				<div className='section pt-7 pb-7'>
					<div className='container'>
						<div className='row'>
							<div className='col-sm-12'>
								<div className='film-detail-video'>
									<a
										className='md-trigger'
										style={{
											height: '600px',
										}}
									>
										<img src={movie.image} alt='' />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='section pb-7'>
					<div className='container'>
						<div className='row'>
							<div className='col-sm-12'>
								<div className='film-detail-title'>
									<h3 className='section-title'> {movie.title}</h3>
									<div className='category' style={{ marginBottom: '20px' }}>
										{movie.category.map((c, index) => (
											<a key={index}>
												{c}
												{index !== movie.category.length - 1 && (
													<span className='ml-2'>/</span>
												)}
											</a>
										))}
									</div>
								</div>
								<div className='film-detail-content'>
									<div
										dangerouslySetInnerHTML={{ __html: movie.description }}
									></div>

									{team.length ? (
										<>
											<div className='mb-4' />
											<h4 styles={{ fontSize: '40px' }}>Team</h4>
											<div className='section bg-lighter p-3 mt-3'>
												<div className='row'>
													<div className='col-md-12 col-sm-12 p-0'>
														<div className='team-masonry masonry-grid-post'>
															{team.map((item, index) => (
																<TeamCard
																	key={index}
																	item={item}
																	projectId={projectId}
																/>
															))}
														</div>
													</div>
												</div>
											</div>
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectDetailsPage;
