export default [
	{
		id: 'adrian_burks',
		project_ids: ['duane_and_skylar'],
		name: 'Adrian Burks',
		title: 'Director / Actor',
		image: '/images/team/adrianburks.jpg',
		bio: `Adrian Burks is an actor, director, writer, and producer. An ex collegiate athlete originally from Milwaukee, Wisconsin, Adrian has been a filmmaker in Los Angeles for 10 years. He has appeared in movies such as the Academy Award winning WHIPLASH, and the comedic cult classic FIRST PERIOD. Adrian has also appeared in television shows such as CBS: CODE BLACK, and ALL ABOUT LIZZIE. Adrian has written 3 short films that went into production, has directed two, and is currently in development for a feature film he wrote and plans to direct. Most Recently Adrian won 6 awards for his film AFTER LAUGHTER in which he co-wrote, co-directed, produced and starred in. He went on to get accepted into the Sony and Film Independent Trust creative writing program to pitch his series: AIMLESSLY WAITING.`,
		order: 1,
	},
	{
		id: 'adrian_burks',
		project_ids: ['morbid_blood'],
		name: 'Adrian Burks',
		title: 'Producer / Actor',
		image: '/images/team/adrianburks.jpg',
		bio: `Adrian Burks is an actor, director, writer, and producer. An ex collegiate athlete originally from Milwaukee, Wisconsin, Adrian has been a filmmaker in Los Angeles for 10 years. He has appeared in movies such as the Academy Award winning WHIPLASH, and the comedic cult classic FIRST PERIOD. Adrian has also appeared in television shows such as CBS: CODE BLACK, and ALL ABOUT LIZZIE. Adrian has written 3 short films that went into production, has directed two, and is currently in development for a feature film he wrote and plans to direct. Most Recently Adrian won 6 awards for his film AFTER LAUGHTER in which he co-wrote, co-directed, produced and starred in. He went on to get accepted into the Sony and Film Independent Trust creative writing program to pitch his series: AIMLESSLY WAITING.`,
		order: 4,
	},
	{
		id: 'frank_capra',
		project_ids: ['duane_and_skylar'],
		name: 'Frank Capra III',
		title: 'Producer',
		image: '/images/team/frank_capra.png',
		bio: `https://www.imdb.com/name/nm0135580/?ref_=fn_al_nm_2`,
		order: 3,
	},
	{
		id: 'frank_capra',
		project_ids: ['morbid_blood'],
		name: 'Frank Capra III',
		title: 'Producer',
		image: '/images/team/frank_capra.png',
		bio: `https://www.imdb.com/name/nm0135580/?ref_=fn_al_nm_2`,
		order: 3,
	},
	{
		id: 'daryl_barnes',
		project_ids: ['morbid_blood'],
		name: 'Daryl Barnes',
		title: 'Director / Writer / Producer',
		image: '/images/team/darrylbarnes.jpg',
		bio: `Daryl Barnes is a director/writer and producer in Los Angeles. He started in the theatre in his native city of Dublin in Ireland. With companies such as the Audrey Meredith Theatre
Company and the Trinity Players. The RSC in London and studied at the
Lee Strasberg Theatre Institute in West Hollywood.
His film credits include Braveheart, Michael Collins, Evelyn and John Boorman’s The
General. He has written for studios such as Millennium Films. His script
The Doctor’s Son, which has Liam Neeson attached, was accepted into the
Squaw Valley Screenwriters Lab with writing mentor Tom Rickman. His
film Beach Memories was an official selection at the Slamdance Film Festival
as was the film The White Room at the Marina Del Rey Film festival. He recently directed/wrote and produced Requiem and is currently in pre-production on a feature film called ‘Second Chance’ that he wrote and will also be directing and producing it along with producer Frank Capra the 3rd, starring Selma Hayek. Daryl is a member of the WGA/DGA and is a lifetime member of the Actors Studio.`,
		order: 1,
	},
	{
		id: 'lindsey_cantrell',
		project_ids: ['duane_and_skylar'],
		name: 'Lindsey Cantrell',
		title: 'Head production designer',
		image: '/images/team/linksey_cantrell.png',
		bio: `https://www.imdb.com/name/nm8482975/?ref_=fn_al_nm_1`,
		order: 5,
	},
	{
		id: 'alicia_blasingame',
		project_ids: ['morbid_blood'],
		name: 'Alicia Blasingame',
		title: 'Producer / Actress',
		image: '/images/team/alicia_blasingame.jpg',
		bio: `Alicia Blasingame is a classically trained actor who has performed on screens and stages across the country. She has appeared in over 50 projects including leading roles in the feature films Family Ornaments (TUBI) and Bodyguard Seduction (LIFETIME). Festival appearances include Cannes, Palm Springs, Fantasia, HollyShorts, Idyllwild, and LA shorts, with wins in the “Best Ensemble Cast”, “Best Picture”, and “Best Actress” categories. During her time in San Francisco she originated the role of Joanne in the premiere of I Used to Write on Walls, a play by Bekah Brunstetter (Maid, This is Us).
Alicia earned her B.A. in Theatre Arts from the University of Puget Sound, minoring in French and graduating Magna Cum Laude with honors. After completing an internship at the LookingGlass Theatre Company in New York, she continued her training in Los Angeles at John Rosenfeld Studios, UCB, and The Imagined Life.
Recent directing and producing credits include Scratch, Requiem, and the upcoming pilot Morbid Blood. Alicia continues to shift her artistic gaze behind the lens in an effort to broaden her connection to storytelling and its capacity to unite people in a shared experience.
`,		
		order: 2,
	},
	{
		id: 'alicia_blasingame',
		project_ids: ['duane_and_skylar'],
		name: 'Alicia Blasingame',
		title: 'Supporting Actress',
		image: '/images/team/alicia_blasingame.jpg',
		bio: `Alicia Blasingame is a classically trained actor who has performed on screens and stages across the country. She has appeared in over 50 projects including leading roles in the feature films Family Ornaments (TUBI) and Bodyguard Seduction (LIFETIME). Festival appearances include Cannes, Palm Springs, Fantasia, HollyShorts, Idyllwild, and LA shorts, with wins in the “Best Ensemble Cast”, “Best Picture”, and “Best Actress” categories. During her time in San Francisco she originated the role of Joanne in the premiere of I Used to Write on Walls, a play by Bekah Brunstetter (Maid, This is Us).
Alicia earned her B.A. in Theatre Arts from the University of Puget Sound, minoring in French and graduating Magna Cum Laude with honors. After completing an internship at the LookingGlass Theatre Company in New York, she continued her training in Los Angeles at John Rosenfeld Studios, UCB, and The Imagined Life.
Recent directing and producing credits include Scratch, Requiem, and the upcoming pilot Morbid Blood. Alicia continues to shift her artistic gaze behind the lens in an effort to broaden her connection to storytelling and its capacity to unite people in a shared experience.
`,
		order: 8,
	},
	{
		id: 'gary_fieldman',
		project_ids: ['expenses'],
		name: 'Gary Fieldman',
		title: 'Producer / Director',
		image: '/images/team/gary_fieldman.png',
		bio: `Gary Fieldman is an award winning Filmmaker from Colorado. He
		editing the award-winning Documentary, Shrouded, and Produced/Directed the award winning short version of Expenses.
		Gary has had an active career in IT for over 25 years, shifting his focus to film after attending Colorado Film School.`,
		order: 1,
	},
	{
		id: 'gary_fieldman',
		project_ids: ['perfect_moments'],
		name: 'Gary Fieldman',
		title: 'Executive Producer',
		image: '/images/team/gary_fieldman.png',
		bio: `Gary Fieldman is an award winning Filmmaker from Colorado. He
		editing the award-winning Documentary, Shrouded, and Produced/Directed the award winning short version of Expenses.
		Gary has had an active career in IT for over 25 years, shifting his focus to film after attending Colorado Film School.`,
		order: 3,
	},
	{
		id: 'darren_barzegar',
		project_ids: ['perfect_moments'],
		name: 'Darren Barzegar',
		title: 'Director',
		image: '/images/team/darren_barzegar.png',
		bio: `Darren Barzegar is an Award-winning filmmaker
		whose projects have aired nationwide. Darren has
		worked with various companies, such as VH1 and
		Mercedez Benz. As a person of color and a
		member of the LGBTQIA+ community he holds
		high responsibility in shining a light on these
		groups and telling their stories that have been
		otherwise overlooked, or stereotyped.`,
		order: 1,
	},
	{
		id: 'darren_barzegar',
		project_ids: ['expenses'],
		name: 'Darren Barzegar',
		title: 'Production Designer',
		image: '/images/team/darren_barzegar.png',
		bio: `Darren Barzegar is an Award-winning filmmaker
		whose projects have aired nationwide. Darren has
		worked with various companies, such as VH1 and
		Mercedez Benz. As a person of color and a
		member of the LGBTQIA+ community he holds
		high responsibility in shining a light on these
		groups and telling their stories that have been
		otherwise overlooked, or stereotyped.`,
		order: 3,
	},
	{
		id: 'kevin_polowy',
		project_ids: ['duane_and_skylar'],
		name: 'Kevin Polowy',
		title: 'Writer / Producer',
		image: '/images/team/kevin_polowy.png',
		bio: `https://www.imdb.com/name/nm3778700/?ref_=fn_al_nm_1`,
		order: 2,
	},
	{
		id: 'theodore_wright',
		project_ids: ['duane_and_skylar'],
		name: 'Theodore Wright IV',
		title: 'Artistic director (Emmy award winner)',
		image: '/images/team/theodore_wright.png',
		bio: `https://www.imdb.com/name/nm12202115/?ref_=nv_sr_srsg_1_tt_0_nm_7_q_theodore%2520wright`,
		order: 4,
	},
	{
		id: 'daryl_barnes',
		project_ids: ['duane_and_skylar'],
		name: 'Daryl Barnes',
		title: 'Producer',
		image: '/images/team/darrylbarnes.jpg',
		bio: `Daryl Barnes is a director/writer and producer in Los Angeles. He started in the theatre in his native city of Dublin in Ireland. With companies such as the Audrey Meredith Theatre
Company and the Trinity Players. The RSC in London and studied at the
Lee Strasberg Theatre Institute in West Hollywood.
His film credits include Braveheart, Michael Collins, Evelyn and John Boorman’s The
General. He has written for studios such as Millennium Films. His script
The Doctor’s Son, which has Liam Neeson attached, was accepted into the
Squaw Valley Screenwriters Lab with writing mentor Tom Rickman. His
film Beach Memories was an official selection at the Slamdance Film Festival
as was the film The White Room at the Marina Del Rey Film festival. He recently directed/wrote and produced Requiem and is currently in pre-production on a feature film called ‘Second Chance’ that he wrote and will also be directing and producing it along with producer Frank Capra the 3rd, starring Selma Hayek. Daryl is a member of the WGA/DGA and is a lifetime member of the Actors Studio.`,
		order: 6,
	},
	{
		id: 'phae_amara',
		project_ids: ['duane_and_skylar'],
		name: 'Phae Amara',
		title: 'Supporting actress',
		image: '/images/team/phae_amara.png',
		bio: `https://www.imdb.com/name/nm6599784/?ref_=fn_al_nm_1`,
		order: 7,
	},
	// {
	// 	id: '',
	// 	project_ids: [''],
	// 	name: '',
	// 	title: '',
	// 	image: '/images/team/',
	// 	bio: ``,
	// },
	{
		id: 'patrick_dingman',
		project_ids: ['expenses', 'perfect_moments'],
		name: 'Patrick Dingman',
		title: 'Director of Photography',
		image: '/images/team/dingman.jpg',
		bio: `Pat Dingman is an Award-winning
filmmaker and graduate of the Colorado
Film School. He currently works full time
as one of Denver’s top Cinematographers.`,
		order: 2,
	},
];
