import projectsData from '../data/projects.data';
import teamData from '../data/team.data';

export const GET_PROJECT = (id) => {
	const project = projectsData.find((item) => item.id === id);
	return project || {};
};

export const GET_TEAM = (project_id) => {
	const team = teamData
		.filter((item) => item.project_ids.includes(project_id))
		.sort((a, b) => a.order - b.order);
	return team;
};

export const GET_TEAM_DETAILS = (id) => {
	const team = teamData.find((item) => item.id === id);
	return team || {};
};
