import React from 'react';
import { Link } from 'react-router-dom';

const TeamCard = ({ item, projectId }) => {
	return (
		<div className='col-md-3 col-sm-6 film-masonry-item masonry-item cinematographers designers'>
			<div className='team-item p-0'>
				<div className='thumb' style={{ height: '220px', background: 'black' }}>
					<Link to={`/projects/${projectId}/team/${item.id}`}>
						<img
							src={item.image}
							style={{
								height: '100%',
								objectFit: 'contain',
							}}
							alt=''
						/>
					</Link>
				</div>
				<div className='info'>
					<div className='name'>
						<Link to={`/projects/${projectId}/team/${item.id}`}>
							{item.name}
						</Link>
					</div>
					<div className='tagline'>{item.title}</div>
					{/* <div className='socials'>
						<a
						// href='#'
						>
							Facebook
						</a>
						<a
						// href='#'
						>
							Twitter
						</a>
						<a
						// href='#'
						>
							Youtube
						</a>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default TeamCard;
