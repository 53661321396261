import React, { useEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
const FeatureCard = ({ item, id }) => {
	return (
		<div
			id={`section-${id}`}
			className='film-one-page-item'
			style={{
				paddingTop: '5px',
			}}
		>
			<div className='film-one-page-item-inner'>
				<div className='col-md-6 '>
					<div className='text'>
						<Link to={`/projects/${item.id}`}>
							<div className='time' style={{ color: 'red' }}>
								{item.category}
							</div>
							<div className='title'>{item.title}</div>
						</Link>
					</div>
				</div>
				<div className='col-sm-12 col-md-6 video__wrapper'>
					<div className='video'>
						<div className='video-inner'>
							<Link
								href={`/projects/${item.id}`}
								style={{ outline: 'none', border: '0px' }}
								className='md-trigger'
							>
								<img src={item.image} alt='' />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeatureCard;
