import featuersData from '../../data/featuers.data';

import React from 'react';
import FeatureCardList from '../../containers/FeatureCardList';
import bannerData from '../../data/projects.data';

const ProjectsPage = () => {
	return (
		<>
			<FeatureCardList data={bannerData} />
		</>
	);
};

export default ProjectsPage;
