import React from 'react';
import HomeBanner from '../containers/HomeBanner/HomeBanner';

const HomePage = () => {
	return (
		<>
			<HomeBanner />
			{/* <HomeAbout /> */}
			{/* <HomeExpenses />
			<HomePerfectMoments />
			<HomeServices />
			<HomeGallery /> */}
		</>
	);
};

export default HomePage;
