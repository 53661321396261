import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<div
			// className='section section-404 section-cover'
			style={{
				marginTop: '100px',
			}}
		>
			<div className='container-fluid '>
				<div className='row'>
					<div className='col-sm-12'>
						<div className='content-404'>
							<h3>Oops! Page not found!</h3>
							<img src='images/404.png' alt='' />
							<span>
								Please go back to <Link to='/'>Homepage</Link>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;
