const AboutPage = () => {
	return (
		<>
			<div className='section page-title'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='text-center-sm'>
								<h1 className='title'>About Us</h1>
								<div className='breadcrumbs'>
									<ul>
										<li>
											<a href='index.html'>Home</a>
										</li>
										<li>About 01</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='section pt-16 pb-10'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8 col-sm-12'>
							<div className='text-center-xs'>
								<img src='images/film_8.jpg' alt='' className='mb-3' />
								<h2 className='section-title fz-40'>We Are The Best</h2>
								<p>
									We are a creative film and photo production company hungry for
									quality in aesthetics. To create modern recognizable stuff we
									are working with a strong network of experienced
									professionals. We set up teams to shape your identity, push
									your idea and manage the workflow from pre- to
									post-production.
								</p>
								<div className='mt-4' />
								<a href='#' target='_self' className='button-2'>
									Hire us now
								</a>
								<div className='mb-9' />
							</div>
						</div>
						<div className='col-md-4 col-sm-12'>
							<div className='mt-3' />
							<div className='film-single-testimonial small'>
								<div className='item'>
									<div className='photo'>
										<img src='images/quote_bg_2.png' alt='' />
									</div>
									<div className='text'>
										If you or your organization have a story to tell, an idea to
										share or word to spread, the Nine Studio can help you bring
										it to life.
									</div>
									<div className='info'>
										<div className='author'>
											<span className='name'>Jerry Hansen</span>
											<span className='tagline'>Actor</span>
										</div>
									</div>
									<div className='mt-5' />
									<img src='images/film_9.jpg' alt='' />
								</div>
							</div>
							<div className='mb-9' />
						</div>
					</div>
					<div className='row'>
						<div className='col-md-7 col-sm-12'>
							<div className='film-list-member'>
								<div className='item'>
									<div className='photo'>
										<img src='images/team/team_6.jpg' alt='' />
									</div>
									<div className='info'>
										<span className='name'>John Anderson</span>
										<span className='tagline'>/ Director</span>
									</div>
								</div>
								<div className='item'>
									<div className='photo'>
										<img src='images/team/team_7.jpg' alt='' />
									</div>
									<div className='info'>
										<span className='name'>Fabian Hellgardt</span>
										<span className='tagline nd-font'>/ Creative Director</span>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-5 col-sm-12'>
							<h2 className='section-title fz-40 mb-3'>Creative Directors</h2>
							<p className='mb-3'>
								Since 2008, we shot more than 50 short formats (commercials,
								music videos, documentaries and short movies). We set up teams
								to shape your identity, push your idea and manage the workflow
								from pre- to post-production.
							</p>
							<div className='icon-boxes icon-boxes-small'>
								<div className='icon-boxes-icon'>
									<i className='ion-trophy' />
								</div>
								<h5 className='icon-boxes-title'> 06 Awards in 2014/2015.</h5>
								<div className='icon-boxes-content'>
									These awards are both national and international
									<br /> in 2014/2015
								</div>
							</div>
							<div className='mb-4' />
							<div className='icon-boxes icon-boxes-small'>
								<div className='icon-boxes-icon'>
									<i className='ion-trophy' />
								</div>
								<h5 className='icon-boxes-title'> 04 Awards in 2016</h5>
								<div className='icon-boxes-content'>
									These awards are both national and international
									<br /> in 2016
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section bg-light pt-14 pb-8'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='text-center'>
								<h3 className='sub-title primary-color'> Filmming Services</h3>
								<h2 className='section-title mb-2'>Explore Services</h2>
								<p className='mb-4'>
									Doloremque, ad. Vero veniam dicta aliquid blanditiis ullam
									quibusdam, accusamus, <br />
									eius quia molestias nihil temporibus nam praesentium odit
									repellat.
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4'>
							<div className='icon-boxes icon-boxes-bg text-center'>
								<div
									className='icon-boxes-icon'
									style={{
										backgroundImage: 'url(images/icon-boxes/icon_bg_1.png)',
										objectFit: 'contain',
									}}
								>
									<i className='ns-tripod' />
								</div>
								<div className='icon-boxes-inner'>
									<h5 className='icon-boxes-title'>FILMING SERVICES</h5>
									<div className='icon-boxes-content'>
										We can film your project whether it is a commercial
										advertise, a short film or a document film. We have
										expertise in these fields.
									</div>
									<div className='icon-boxes-link'>
										<a href='#'>
											<span className='ion-android-arrow-forward' />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='icon-boxes icon-boxes-bg text-center'>
								<div
									className='icon-boxes-icon'
									style={{
										backgroundImage: 'url(images/icon-boxes/icon_bg_2.png)',
										objectFit: 'cover',
									}}
								>
									<i className='ns-seats' />
								</div>
								<div className='icon-boxes-inner'>
									<h5 className='icon-boxes-title'>
										WORKSHOPS &amp; PRIVATE TUITION
									</h5>
									<div className='icon-boxes-content'>
										Some workshops have invited us to come lecturing. We also
										offer private tuitions for absolute beginners or mid-level
										producers who need more advice.
									</div>
									<div className='icon-boxes-link'>
										<a href='#'>
											<span className='ion-android-arrow-forward' />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='icon-boxes icon-boxes-bg text-center'>
								<div
									className='icon-boxes-icon'
									style={{
										backgroundImage: 'url(images/icon-boxes/icon_bg_3.png)',
										objectFit: 'cover',
									}}
								>
									<i className='ns-video-camera' />
								</div>
								<div className='icon-boxes-inner'>
									<h5 className='icon-boxes-title'>Equipment hire</h5>
									<div className='icon-boxes-content'>
										Sometimes we don’t use our equipment so you can hire them
										for your filming. Check our schedule and contact if it suits
										your time.
									</div>
									<div className='icon-boxes-link'>
										<a href='#'>
											<span className='ion-android-arrow-forward' />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='section section-bg-5 section-cover pt-11 pb-11'>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12'>
							<div className='text-center mb-7'>
								<h3 className='sub-title primary-color'>Our Best Films</h3>
								<h2 className='section-title mb-2'>Featured Films</h2>
								<p>
									Have a look at our best films which are high in quality and
									receive positive responses <br />
									from audience. You are gonna have these great films as well.
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='film-list-item'>
							<div className='col-md-6 col-thumb'>
								<div className='thumb'>
									<a href='film-detail.html'>
										<img src='images/projects/project_7.jpg' alt='' />
									</a>
								</div>
							</div>
							<div className='col-md-6 col-info'>
								<div className='info'>
									<div className='title'>
										<a href='film-detail.html'> The Secret Scripture </a>
									</div>
									<div className='category'>
										<a href='#'>Anthology Film</a>
										<a href='#'>Short Film</a>
									</div>
									<div className='excerpt'>
										In our new film The Secret Scripture, based on the novel by
										Sebastian Barry, the six-time Oscar nominated director
										returns to themes familiar to him: politics, religion,
										family and truth. Vanessa Redgrave stars as Rose McNulty, a
										woman
									</div>
									<div className='meta'>
										<span className='view'>215 Views</span>
										<span className='like'>
											<a href='#' className='like-btn'>
												4 Likes
											</a>
										</span>
										<span className='comment'>0 Comment</span>
									</div>
								</div>
							</div>
						</div>
						<div className='film-list-item'>
							<div className='col-md-6 col-md-push-6 col-thumb'>
								<div className='thumb'>
									<a href='film-detail.html'>
										<img src='images/projects/project_10.jpg' alt='' />
									</a>
								</div>
							</div>
							<div className='col-md-6 col-md-pull-6 col-info'>
								<div className='info'>
									<div className='title'>
										<a href='film-detail.html'> Kicks </a>
									</div>
									<div className='category'>
										<a href='#'>Short Film</a>
									</div>
									<div className='excerpt'>
										The Tribeca Film Festival kicked off on April 14 with the
										opening night premiere of Justin Tipping’s Kicks, an
										ambitious coming-of-age film set in an inner city enclave in
										Northern California. Though it’s Tipping’s feature debut,
										he’s far
									</div>
									<div className='meta'>
										<span className='view'>155 Views</span>
										<span className='like'>
											<a href='#' className='like-btn'>
												0 Like
											</a>
										</span>
										<span className='comment'>0 Comment</span>
									</div>
								</div>
							</div>
						</div>
						<div className='film-list-item'>
							<div className='col-md-6 col-thumb'>
								<div className='thumb'>
									<a href='film-detail.html'>
										<img src='images/projects/project_11.jpg' alt='' />
									</a>
								</div>
							</div>
							<div className='col-md-6 col-info'>
								<div className='info'>
									<div className='title'>
										<a href='film-detail.html'> Raising Bertie Up </a>
									</div>
									<div className='category'>
										<a href='#'>Short Film</a>
									</div>
									<div className='excerpt'>
										Raising Bertie follows three young men over the course of
										five years as they grow into adulthood in Bertie County, a
										rural African-American-led community in North Carolina.
										Director Margaret Byrne had originally set out to make
									</div>
									<div className='meta'>
										<span className='view'>177 Views</span>
										<span className='like'>
											<a href='#' className='like-btn'>
												0 Like
											</a>
										</span>
										<span className='comment'>0 Comment</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutPage;
