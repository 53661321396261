import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ title, links }) => {
	return !title ? null : (
		<div className='section page-title'>
			<div className='container'>
				<div className='row'>
					<div className='col-sm-12'>
						<div className='text-center-sm'>
							<h1 className='title'>
								{title
									.split(' ')
									.map((text, index) =>
										index % 2 === 0 ? (
											text
										) : (
											<span style={{ margin: '0px 10px' }}>{text}</span>
										)
									)}
							</h1>
							<div className='breadcrumbs'>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									{links?.map((item, index) => (
										<li>
											<Link to={item.path}>{item.title}</Link>
										</li>
									))}
									<li>{title}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Breadcrumb;
