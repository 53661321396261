import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_TEAM_DETAILS } from '../../services/movies.service';
import Breadcrumb from '../../components/Breadcrumb';

const TeamDetails = () => {
	const { projectId, id } = useParams();
	const [team, setTeam] = useState(null);

	console.log('params: ', { projectId, id });

	useEffect(() => {
		getData(id);
	}, [id]);

	const getData = async (id) => {
		const res = await GET_TEAM_DETAILS(id);
		setTeam(res);
	};

	if (!team) return null;
	return (
		<>
			<div>
				<Breadcrumb
					title={team.name}
					links={[
						{
							title: projectId,
							path: `/projects/${projectId}`,
						},
					]}
				/>

				<div className='section section-bg-13 pt-13 pb-9'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-md-6'>
								<div className='mtn-19 mb-4 text-center'>
									<img src={team.image} alt='' className='box-shadow' />
								</div>
							</div>
							<div className='col-md-6'>
								<div className='film-member-skill mw-500'>
									<div className='title'>{team.title}</div>
									{team.bio.startsWith('http') ? (
										<a
											href={team.bio}
											target='_blank'
											className='small mont-font fw-bolder'
											style={{ textDecoration: 'underline', color: 'blue' }}
										>
											{team.bio}
										</a>
									) : (
										<p className=' small mont-font fw-bolder'>{team.bio}</p>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TeamDetails;
