import React from 'react';

const Logo = ({ className, color }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height={60}
			viewBox='0 0 300 114'
			// className={className}
			fill={color ? color : className === 'logo' ? 'white' : 'black'}
		>
			<path d='M44.8 19.9c.3 3.9-.2 5.9-2.3 9.5l-2.6 4.5 4.3.4 4.3.4-4.2.1c-2.4.1-4.3.4-4.3.6 0 .2.7 2 1.5 3.9 1.3 3 1.3 5.2.3 14.4l-1.2 10.8-1.9-6c-1-3.3-1.8-8.4-1.7-11.3.1-6.2-2-10.6-6-12.7-3.8-2-3.7-2-4.2 1.7-.8 5 .9 9.4 4.7 12.6 2.6 2.1 4.1 4.7 6 10.1l2.5 7.4-6.3-6.9C23.6 48.2 22.1 47 18.4 47c-1.8 0-4.4.5-5.8 1.1l-2.5 1.2 2.1 3c2.9 4 7.6 5.9 11.9 4.7 4-1.2 6.1 0 11.3 6.3 5.3 6.5 8.9 15.1 9.4 22.4l.4 6.3H286V15H44.5l.3 4.9zM285 53v37H46v-5c0-2.7-.9-7.3-2-10.2-1.1-2.9-2-5.4-2-5.5 0-.1 1.4-1.8 3.1-3.8 2.4-2.7 3.9-3.5 6.4-3.5 4 0 8.3-3.2 10.2-7.8 1.7-4 1.4-4.5-2.9-4.4-4.8.1-7.7 2.2-10.3 7.2-1.3 2.5-3.3 5.6-4.5 7l-2.1 2.5.5-4c.3-2.2.8-7.4 1.2-11.5.4-5.4 1.3-8.5 3-11.2 2.8-4.4 2.9-5.4.9-10.1-.8-2-1.5-5.9-1.5-8.7v-5h239v37zM31 39.1c0 1.3.4 1.8 1.2 1.3.8-.5.9-.2.5.9-.3 1-.2 1.7.4 1.7.6 0 .7.4.4 1-.3.5-.1 1 .5 1s.8.5.4 1.2c-.5.8-1.1.5-1.7-1-.6-1.2-1.4-2.2-1.9-2.2s-.6-.5-.3-1c.3-.6.1-1-.5-1s-.8-.5-.5-1c.3-.6.1-1-.5-1s-.8-.5-.5-1c1.1-1.7 2.5-1.1 2.5 1.1zM20.1 50.9c.7-1.1.9-1.1.9-.1 0 .7.3 1.1.8.9.4-.3 1.2.1 1.9.8.9 1 .8 1.4-.8 1.8-1.1.3-1.6.1-1.3-.5.8-1.2-2.8-.6-3.9.7-.5.6-.7.4-.4-.5.2-.8-.5-2.1-1.7-2.8-2.1-1.3-2.1-1.3.2-.9 1.2.3 2.3 0 2.3-.6.1-.7.4-.3.6.7.4 1.5.7 1.6 1.4.5zM60 52.7c0 .1-.9 1.2-2 2.3-2.1 2.1-3.9.9-1.8-1.3 1.1-1.2 3.8-1.9 3.8-1z' />
			<path d='M72 40.5s-.2 6-.3 13.3L71.5 67h2.8c2.5 0 2.7-.3 2.7-4.3v-4.2l5 .3c5 .4 5 .4 5-2.7s-.1-3.1-5-3.1h-5v-3.5c0-3.5 0-3.5 4.5-3.5 4.2 0 4.5-.2 4.5-2.8 0-2.7 0-2.7-7-2.7h-7zM90.8 53.8 90.5 67H107v-2.8c0-2.9 0-2.9-5.5-2.4l-5.5.4V51.4c0-10.9 0-10.9-2.4-10.9h-2.5l-.3 13.3zM114.4 41.9c-3.1 1.9-6.4 8.2-6.4 12.3 0 1.2.9 4.1 2 6.4 3.5 7.2 11.6 9.5 18.1 5.1 4.4-3 5.9-6 5.9-12.6 0-4.9-.3-5.7-3.9-9.2-3.3-3.3-4.5-3.9-8.2-3.9-2.5 0-5.7.8-7.5 1.9zm12.2 6.2c2.8 3.1 2.5 9.6-.5 12-3.2 2.6-6.7 2.4-9.6-.6-3.1-3-3.2-7.3-.4-10.9 2.6-3.3 7.7-3.6 10.5-.5zM138 53.7V67h3.1c3 0 3.1-.1 2.7-4-.3-3.6-.1-4 2-4 1.8 0 2.7.9 3.9 4 1.4 3.4 2.1 4 4.5 4 1.5 0 2.8-.2 2.8-.5 0-.2-1-2.5-2.2-4.9-2-4.3-2.1-4.6-.5-6.4 1-1.1 1.7-3.5 1.7-5.9 0-5.8-2.5-7.7-11.1-8.3l-6.9-.5v13.2zm11.8-6.5c1.6 1.6 1.5 3.3-.4 5.2-2.9 3-5.2 2-5.6-2.4-.3-3.7-.2-4 2.2-4 1.4 0 3.1.5 3.8 1.2zM160 53.7V67h17v-2.8c0-2.7-.1-2.7-5.7-2.7-4.7 0-5.8-.3-6.1-1.8-.3-1.4.4-1.7 4.7-1.7 4.8 0 5.1-.2 5.1-2.5s-.3-2.5-4.8-2.5h-4.8v-3.5c0-3.5.1-3.5 4.3-3.5 4 0 4.3-.2 4.3-2.8 0-2.7 0-2.7-7-2.7h-7v13.2zM188 53.7c0 10 .3 13.3 1.3 13.3.8 0 1.1-1.4.9-4.5l-.4-4.5h6.1c4.8 0 6.1-.3 6.1-1.5s-1.3-1.5-6-1.5h-6V43h5c3.5 0 5-.4 5-1.3 0-1-1.7-1.3-6-1.3l-6 .1v13.2zM206 53.4c0 10.2.3 13.6 1.3 13.6 1.6 0 1.4-26.3-.2-26.8-.8-.3-1.1 4.1-1.1 13.2zM214.7 40.7c-.4.3-.7 6.4-.7 13.5V67h7.5c4.3 0 7.5-.4 7.5-1s-2.7-1-6-1h-6V52.5c0-11.4-.5-13.7-2.3-11.8zM233 53.3c0 8.5.4 13.7 1 13.7s1-4.2 1-10.7V45.5l4.7 4.7 4.6 4.6 4.3-4.6 4.3-4.7.1 10.7c0 15.7 1.8 13.4 2.2-2.7.2-7.4.2-13.5.1-13.5s-2.7 2.6-5.8 5.7l-5.6 5.8-5.4-5.9-5.5-5.9v13.6zM273.8 41.9c-1.5 1.2-3.7 5.2-5.6 10.3-3.8 9.9-5.6 12.8-8.3 12.8-1 0-1.9.4-1.9 1 0 1.3 3.6 1.3 6 0 1-.5 3.3-4.7 5-9.2 4.6-11.8 5.7-13.8 8.1-13.8 1.2 0 1.9-.5 1.7-1.2-.7-2-2.3-1.9-5 .1z' />
		</svg>
	);
};

export default Logo;
