import React, { useEffect } from 'react';
import navbarData from '../data/navbar.data';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../asset/Logo';
const Navbar = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		const handleScroll = () => {
			const headerDesktop = document.querySelector('.header-desktop');
			const backToTop = document.querySelector('#backtotop');

			if (window.scrollY > 0) {
				headerDesktop.classList.add('scrolling-menu');
			} else {
				headerDesktop.classList.remove('scrolling-menu');
			}

			if (window.scrollY > 100) {
				backToTop.classList.add('show');
			} else {
				backToTop.classList.remove('show');
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<header
				id='header'
				className={`header header-desktop header-1  ${
					pathname === '/' ? 'header-overlay' : ''
				}`}
				style={{
					background: pathname === '/' ? 'rgba(0,0,0,0.7)' : '',
				}}
			>
				<div className='container-fluid'>
					<div
						className='row'
						style={{
							display: 'flex',
							padding: '10px 0px',
						}}
					>
						<div>
							<div
								className='header-left'
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Link to='/' id='branding_logo'>
									{pathname === '/' ? (
										<>
											<Logo className='logo' />
										</>
									) : (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Logo color='black' className='logo-alt' />
										</div>
									)}
								</Link>
							</div>
						</div>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<div className='header-right '>
								<nav id='menu' className='menu menu-primary '>
									<ul>
										{navbarData.map((item, index) => (
											<li
												key={index}
												style={{
													marginLeft: '50px',
												}}
											>
												<Link
													style={{
														padding: 0,
														margin: 0,
													}}
													to={item.path}
												>
													{item.title}
												</Link>
											</li>
										))}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
			<header
				className={`header header-mobile ${
					pathname === '/' && 'header-overlay'
				}`}
				style={{
					padding: '10px',
					paddingBottom: '20px',
					background: pathname === '/' ? 'rgba(0,0,0,0.7)' : '',
				}}
			>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12'>
							<div className='header-center'>
								<Link to='/' id='branding_logo'>
									{pathname === '/' ? (
										<Logo className='logo' />
									) : (
										<Logo className='logo-alt' />
									)}
								</Link>
							</div>
						</div>
					</div>
					<div className='row'>
						<div
							className='col-md-12'
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div className='header-right'>
								<nav id='menu' className='menu menu-primary p-0'>
									<ul>
										{navbarData.map((item, index) => (
											<li key={index}>
												<Link style={{ padding: '0px 20px' }} to={item.path}>
													{item.title}
												</Link>
												{/* <Link to={item.path}>{item.title}</Link> */}
											</li>
										))}
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Navbar;
