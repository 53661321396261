import React from 'react';
import FeaturePagination from '../../components/FeaturePagination';
import FeatureCard from '../../components/FeatureCard';

const FeatureCardList = ({ data }) => {
	return (
		<>
			<FeaturePagination count={data.length} />
			<div className='main'>
				<div className='section'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-sm-12'>
								<div className='film-one-page'>
									{data.map((item, index) => (
										<FeatureCard key={index} item={item} id={index + 1} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FeatureCardList;
