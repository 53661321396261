export default [
	{
		id: 'expenses',
		title: 'Expenses',
		category: ['Crime Drama'],
		image: 'banners/expenses.png',
		description: `
		<h4>Logline</h4>
		<p>
		A financially-strapped hitman juggles bargain hits with family life, as his latest cheap job spirals into a high-stakes game of survival and danger.
		</p>
		<h4 style="margin-top: 30px">Synopsis</h4>
		<p>
		Charlie’s financial woes are piling up fast: over-priced school supplies, outrageous grocery bills, and car repairs. Unfortunately, today’s economy has been brutal for his line of work: killing people. When offered a new job, his initial excitement plummets when his boss offers him a measly $10,000 for a new hit. To make matters worse, he must pay his partner’s expenses from this amount! While on his  assignment, news from home brings more financial hardship. When his boss offers him additional financial incentives for completing increasingly dangerous tasks Charlie must knuckle down and take additional risks to provide for his family. Will Charlie’s financial desperation lead him to destruction or prosperity?
		</p>
		`,
	},
	// {
	// 	id: 'duane_and_skylar',
	// 	title: 'Duane and Skylar',
	// 	category: ['Comedy'],
	// 	image: 'banners/duaneandskylar_720.jpg',
	// 	description: `
	// 	<p>
	// 	<i>Duane and Skylar</i> features two high	school teen rivals who on one fateful day mysteriously swap bodies- setting
	// 	off a whirlwind of outrageous mishaps throughout each others lives. Duane,
	// 	an all around talented Black graphic artist raised by his widowed father,
	// 	and Skylar, a rap-loving White teen with a tough cop father find themselves
	// 	in an utterly bizarre situation.
	// 	</p>
	// 	<p>
	// 	The comedic rollercoaster takes them on unexpected journeys, from a love
	// 	triangle to crude behavior at family dinners- all while they explore what it
	// 	truly means to walk in someone else’s shoes. <i> “DUANE & SKYLAR”</i>
	// 	combines the absurdity of body-swap humor with the heartwarming
	// 	essence of a coming-of-age story. It delves into the nuances of identity,
	// 	friendship, and self-discovery, as Duane and Skylar learn that even the most
	// 	unlikely friendships can uncover our hidden similarities and celebrate our
	// 	unique differences. <i>“DUANE & SKYLAR”</i> encapsulates the heart of teenage
	// 	friendship like <i>SuperBad</i>, the absurd pacing of <i>The Change Up</i>, and fun of
	// 	<i>Freaky Friday</i>.
	// 	</p>
	// 	<p>
	// 	Get ready for a sidesplitting adventure as these two teens strive to undo the
	// 	“freaky” twist of fate that brought them together.
	// 	</p>
	// 	`,
	// },
	// {
	// 	id: 'morbid_blood',
	// 	title: 'Morbid Blood',
	// 	category: ['Horror', 'Drama'],
	// 	image: 'banners/mmiwhand_90__720.jpg',
	// 	description: `<i>Morbid Blood</i> is a gripping thriller following two FBI agents tasked with investigating
	// 	a gruesome murder in the quiet town of Griffith Pines. The victim, a Navajo woman, was
	// 	found brutally assaulted and murdered near a riverbed- sending shockwaves through
	// 	the community. The agents quickly discover zero murders have been reported in the
	// 	town for nearly a decade, despite the increasing number of disappearances. Their
	// 	suspicions rise as they unravel falsities within the tight-knit community.
	// 	Their primary lead is the victim’s adopted brother, Justin, who seemingly knows
	// 	nothing about the crime. As the agents dig deeper, they uncover disturbing reports of
	// 	a secretive and strange sect known as ‘Dream Masters.’ This enigmatic group seems to
	// 	have a mysterious connection to the series of disappearances of women in the town.
	// 	The agents find themselves entangled in a web of dark rituals, hidden agendas, and
	// 	the town’s unsettling past. Morbid Blood is a suspenseful, chilling exploration of the
	// 	lengths people will go to protect their secrets and the horrors that lurk beneath the
	// 	surface of seemingly tranquil native communities.
	// 	`,
	// },
	{
		id: 'perfect_moments',
		title: 'Perfect Moments',
		category: ['Comedy', 'Drama', 'French New Wave'],
		image: 'banners/perfectmonents.png',
		description: `
		<p>
		Meet Michel, an unsuccessful, disillusioned and
	introspective fuckboy in his thirties navigating through a
	vibrant yet desolate landscape of romantic pursuits and
	existential dread. Michel embarks on a journey through a
	whimsical world where his best friend is a sagacious yet
	sassy alien, and people gift each other hand grenades.
	His life, punctuated by an eclectic series of dates
	involving peculiar nose-picking habits and hilariously
	tragic attempts at connection, mirrors his inner
	turbulence in an endless search for ‘forever‘.
		</p>
		<p>
		Michel, with his hypercritical lens and idealistic notions of love,
		wrestles with loneliness that’s both absurd and real.
	Throughout the story, Michel learns to appreciate
	imperfections and finds solace in accepting life's imperfect moments.
		</p>

		<a href="https://www.perfectmomentsfilm.com" target="_blank">More Info: <span style="color:blue; text-decoration: underline">https://www.perfectmomentsfilm.com</span></a>
		`,
		frame_link: 'https://www.perfectmomentsfilm.com',
	},
];
