import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
const FeaturePagination = ({ count }) => {
	const [active, setActive] = useState(1);
	const arr = Array.from(Array(count).keys()).map((item) => item + 1);

	const listenScrollEvent = () => {
		if (window.scrollY > 1000) {
			setActive(3);
		} else if (window.scrollY > 500) {
			setActive(2);
		} else if (window.scrollY < 200) {
			setActive(1);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);

		return () => {
			window.removeEventListener('scroll', listenScrollEvent);
		};
	}, []);

	return (
		<ul className='onepage-pagination'>
			{arr.map((item) => (
				<li key={item}>
					<Link
						data-index={item}
						to={`#section-${item}`}
						className={active === item ? ' onepage current' : 'onepage '}
					></Link>
				</li>
			))}
		</ul>
	);
};

export default FeaturePagination;
